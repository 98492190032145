












































































































.code-generation-step {
  text-align: center;
  max-width: 968px;
  margin: 0 auto;
  .text-red {
    color: #bb3232;
  }
  textarea {
    resize: none;
    &[readonly] {
      background: #fff;
    }
  }
}
