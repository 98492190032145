


































































































































































































































































.coupon-types {
  .card-img-top {
    max-width: 100%;
    width: 150px;
    margin: 0 auto;
    min-height: 150px;
  }
}

.card-wrapper {
  max-width: 1024px;
}

.editor-container {
  display: flex;
  width: 100%;
  .design-box {
    width: 460px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  @media screen and (max-width: 992px) {
    .design-box {
      margin: 0 auto;
    }
    flex-direction: column;
  }
}
