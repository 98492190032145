






































































































.form-group {
  position: relative;
}
.input-container {
  display: flex;
  .form-control {
    flex: 1 1 auto;
  }
}
.btn-upload {
  background: transparent;
  border: 1px solid #e2e7f1;
  margin-left: 5px;
  border-radius: 5px;
  padding: 0px 11px;
  color: #414141;
  cursor: pointer;
  &.btn-remove {
    color: #ec0303;
    border-color: #ec0303;
  }
}

.icons-modal {
  position: absolute;
  width: 370px;
  background: #fff;
  border: 1px solid #e2e7f1;
  border-radius: 3px;
  box-shadow: 0px 0px 4px #e2e7f1;
  z-index: 50;
  padding: 10px;
  left: 8px;
  top: 60px;
  .icon-item {
    color: #747b87;
    border: 1px solid #747b87;
    display: inline-block;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    margin: 4px;
    cursor: pointer;
  }
  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e2e7f1;
    top: -10px;
    right: 58px;
  }
  &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #fff;
    top: -9px;
    right: 60px;
  }

  .list-images {
    max-height: 340px;
    overflow-y: scroll;
  }
}
