











































































































































































































































































































































































































































































































































.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}

.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border: 2px solid #d9dfe6;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &.design {
    min-width: 90%;
  }
  &:before {
    content: " ";
    border: 2px solid #d9dfe6;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -9px;
    left: -10px;
    border-radius: 36px;
    z-index: -1;
  }
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.section-select {
  cursor: pointer;
  border: 1px solid transparent;
}
.device-container.design .section-select:hover {
  border-color: #4284f4;
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }
}
