




















































































































































































































































































































































































































.sms-widget {
  background-color: transparent;
  .powered-by-content {
    color: #666;
    padding-right: 10px;
    padding-top: 5px;
    display: block;
    font-size: 14px;
    text-align: center;
    a {
      color: #2499ef;
      font-weight: bold;
      &:hover {
        text-decoration: underline!important;
      };
    }
  }
  .widget-block {
    border: 2px dashed rgba(0, 27, 72, 0.12);
    border-radius: 8px;
    cursor: pointer;
    transition: border 300ms ease, background-color 300ms ease;
    margin-bottom: 1.4vh;
    position: relative;
    &.widget-content {
      border-color: transparent;
    }
    &:hover {
      border-color: #2399f0 !important;
      background-color: rgba(35, 153, 240, 0.05);
    }
  }
  .widget-toggle {
    margin-top: 25px;
    text-align: right;
    cursor: pointer;
  }
  .widget-default {
    text-align: center;
    img {
      width: 40px;
      margin: 20px;
    }
    .widget-optional {
      opacity: 0.5;
    }
    .widget-caption {
      color: rgba(0, 27, 72, 0.54);
      font-size: 16px;
    }
  }
  .widget-caption {
    margin-bottom: 20px;
  }
  .widget-copyright {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 13px;
      color: rgba(0, 27, 72, 0.54);
      display: block;
    }
  }
  .phone-input {
    margin-bottom: 15px;
    position: relative;
    color: #414141;
    align-items: center;
    display: flex;
    .phone-code {
      position: absolute;
      padding: 0px 8px;
      img {
        width: 24px;
        margin-right: 5px;
      }
      .code {
        font-weight: 600;
        font-size: 12px;
      }
    }
    input {
      background-color: inherit;
      border: none;
      padding-left: 60px;
      font-weight: 600;
      display: block;
      border-radius: 5px;
      width: 100%;
    }
  }
}
