











































































































































.landing-builder-container {
  display: flex;
  width: 100%;
  .design-box {
    width: 460px;
  }
  .preview-box {
    flex: 1 1 auto;
  }
  .nav-tabs {
    justify-content: center;
    a {
      opacity: 0.6;
      &.active {
        background: transparent;
        color: #495057;
        opacity: 1;
      }
    }
  }
}
