





































































































































































































































































































































































































































































































































































































































































































.live {
  .device-container  {
    border-radius: 0px;
    border: none;
    &:before, &:after {
      display: none;
    }
  }
}
.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}

.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border: 2px solid #d9dfe6;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &.design {
    min-width: 90%;
  }
  &:before {
    content: " ";
    border: 2px solid #d9dfe6;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -9px;
    left: -10px;
    border-radius: 36px;
    z-index: -1;
  }
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.section-select {
  cursor: pointer;
  border: 1px solid transparent;
}
.device-container.design .section-select:hover {
  border-color: #4284f4;
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

.list-points-section {
  display: flex;
  background: #FFF;
  text-align: center;
  .point-item {
    padding: 10px 0px;
    border-right: 2px solid #bcb8b8;
    width: 25%;
    &:last-child {
      border: none;
    }
    .point {
      font-size: 25px;
      font-weight: 700;
    }
    .desc {
      color: #8a8a8a;
    }
  }
}

.main-section {
  padding: 15px 0px;
}

.reward-section {
  display: flex;
  .get-points {
    padding: 20px;
    border-right: 2px solid #b3b0b0;
    width: 50%;
    .link {
      margin-bottom: 20px;
      display: flex;
      input {
        width: 100%;
        border: none;
        border-radius: 3px;
        box-shadow: 0px 0px 2px #ccc;
        padding: 5px 10px;
      }
      button {
        border: none;
        background: none;
        margin-left: 10px;
        background: #ed7d31;
        color: #FFF;
        border-radius: 5px;
      }
    }
    .list-actions {
      .action-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        font-weight: 600;
        padding: 12px 10px;
        margin-bottom: 5px;
        min-height: 55px;
        .point {
          float: right;
          background: #d9d9d9;
          padding: 7px 10px;
          color: #414141;
          font-weight: 700;
          border-radius: 5px;
          width: 36px;
          text-align: center;
        }
      }
    }
  }
  .rewards {
    width: 50%;
    padding: 20px 15px;
    .list-rewards {
      .reward-item {
        border-radius: 20px;
        padding: 20px;
        text-align: center;
        margin-bottom: 25px;
        position: relative;
        .unlock {
          background: #548235;
          padding: 5px;
          border-radius: 5px;
          text-align: center;
          font-weight: 600;
        }
        .content {
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 16px;
        }
        .icon {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          position: absolute;
          top: -20px;
          left: calc(50% - 20px);
          font-size: 25px;
          line-height: 40px;
          cursor: pointer;
        }
      }
      .progress-container {
        background: #d9d9d9;
        margin-top: 10px;
        border-radius: 10px;
        height: 14px;
        position: relative;
        overflow: hidden;
        .progress-completed {
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: #1ba614;
          border-radius: 10px 0px 0px 10px;
        }
      }
      .progress-stat {
        font-size: 12px;
        text-align: right;
        color: #FFF;
        margin-top: 2px;
      }
    }
  }
  h3 {
    border-bottom: 1px solid #b3b0b0;
    padding-bottom: 10px;
  }
}

.points-div, .rewards-div{
  h3 {
    border-bottom: 1px solid #b3b0b0;
    padding-bottom: 10px;
  }
}

.points-div {
  border-right : 2px solid #b3b0b0;
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .reward-section{
    display:block;
    .get-points {
      width: 100%;
    }
    .rewards {
      width: 100%;
    }

  }

  .points-div, .rewards-div{
    text-align: center;
    border-bottom: 1px solid #b3b0b0;
    padding-top: 7px;
     h3 {
        border-bottom: 0px solid #b3b0b0;
      }
  }

  .selectedDiv {
    background-color: #478c41;
     h3 {
       color: white;
     }
  }

  .unselectedDiv {
     h3 {
       color: black;
     }
  }
  
}
